import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import GameListItem from "../../../Componant/Gamelistitem.js";
import "./match.scss";
import { SwipeableDrawer, Tab, Tabs } from "@mui/material";
import BetDrawer from "../../../Componant/BetDrawer/BetDrawer.js";
import axios from "../../../authAxios.js";
import { useRef } from "react";
import Swal from "sweetalert2";

const IframeRenderer = ({ url }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const updateIframeStyles = () => {
      const iframeDocument = iframeRef.current.contentDocument;

      if (iframeDocument) {
        // Adjust the styles as needed
        iframeDocument.body.style.overflow = "hidden";
        iframeDocument.body.style.maxWidth = "100%";
        iframeDocument.body.style.boxSizing = "border-box";
        iframeDocument.documentElement.style.height = "100%";
        iframeDocument.documentElement.style.overflowY = "hidden";
      }
    };

    window.addEventListener("resize", updateIframeStyles);
    updateIframeStyles(); // Initial update on component mount

    return () => {
      window.removeEventListener("resize", updateIframeStyles);
    };
  }, []);

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
      <iframe
        title="Rendered HTML"
        src={url}
        style={{ width: "100%", height: "100%", border: "none" }}
        ref={iframeRef}
      />
    </div>
  );
};

function Match() {
  const params = useParams();
  const navigate = useNavigate();
  const [upcomingMatches, setUpcomingMatches] = useState([]);
  const [sessionBets, setSessionBets] = useState([1, 4, 7]);
  const [Home, setHome] = useState("");
  const [Away, setAway] = useState("");
  const [bookMaker, setbookMaker] = useState([]);
  const [market_id, setmarket_id] = useState();
  const [odds, setodds] = useState();
  const [diamondFancy, setdiamondFancy] = useState([]);
  const [positionArr, setPositionArr] = useState({});
  const [oddsPositionArr, setOddsPositionArr] = useState({});
  const [fancyPositionArr, setFancyPositionArr] = useState({ No: 0, Yes: 0 });
  const [activeTab, setActiveTab] = useState(0);
  const [fancySessionBet, setFancySessionBet] = useState([]);
  const [playedMatchBets, setPlayedMatchBets] = useState([]);
  const [totalUndeclaredBet, setTotalUndeclaredBet] = useState(0);
  const [matchStartTime, setMatchStartTime] = useState();
  // const [scoreCard, setscoreCard] = useState([])

  // const [score, setScore] = useState([]);

  const [isBettingDrawerOpen, setIsBettingDrawerOpen] = useState(false);
  const [betInfo, setBetInfo] = useState({
    type: "",
    team: "",
    rate: "",
    positionIndex: "0",
    betCategory: "",
    placeBetFun: "",
  });
  const [isFancyBettingDrawerOpen, setIsFancyBettingDrawerOpen] =
    useState(false);
  const [fancyBetInfo, setFancyBetInfo] = useState({
    selection_id: "",
    runnerName: "",
    layPrice1: "",
    laySize1: "",
    backPrice1: "",
    backSize1: "",
    type: "",
    placeBetFun: "",
  });
  const match_id = params.match_id;

  const handleBettingClicked = (
    type,
    team,
    rate,
    positionIndex,
    betCategory,
    placeBetFun
  ) => {
    setBetInfo({ type, team, rate, positionIndex, betCategory, placeBetFun });
    setIsBettingDrawerOpen((prev) => !prev);
  };

  const handleFancyBetClick = (
    selection_id,
    runnerName,
    layPrice1,
    laySize1,
    backPrice1,
    backSize1,
    type,
    placeBetFun
  ) => {
    setFancyBetInfo({
      selection_id,
      runnerName,
      layPrice1,
      laySize1,
      backPrice1,
      backSize1,
      type,
      placeBetFun,
    });
    setIsFancyBettingDrawerOpen((prev) => !prev);
  };

  // let a = {};
  // console.log({ amount, betInfo, Home, Away });
  // if (betInfo.type == "lagai") {
  //   if (betInfo.team == Home) {
  //     a[Home] = Math.round(0.01 * betInfo.rate * amount);
  //     a[Away] = Math.round(-1 * amount);
  //   } else {
  //     a[Away] = Math.round(0.01 * betInfo.rate * amount);
  //     a[Home] = Math.round(-1 * amount);
  //   }
  // } else if (betInfo.type == "khai") {
  //   console.log("HERE IT WAS KHAI CONDITION", betInfo.team == Home);
  //   if (betInfo.team == Home) {
  //     a[Home] = -1 * betInfo.rate;
  //     a[Away] = Math.round(amount);
  //   } else {
  //     a[Away] = -1 * betInfo.rate;
  //     a[Home] = Math.round(amount);
  //   }
  // } else {
  //   // Don't do anything
  //   console.log("CONDITIONS ARE FAILING");
  // }
  // setPositionArr(a);

  const handleOddsBetPlaced = async (amount, betInfo_obj) => {
    try {
      let bet = await axios.post("/transactions/place-odds-bet", {
        bet_amount: amount,
        bet_on: betInfo_obj.team,
        bet_rate: betInfo_obj.rate,
        opponent_team: betInfo_obj.team == Home ? Away : Home,
        bet_type: betInfo_obj.type,
        position: oddsPositionArr,
        market_id: market_id,
        match_id: match_id,
        user_id: localStorage.getItem("user_id"),
        team1: Home,
        team2: Away,
      });
      if (bet.data.status) {
        console.log("ODDS API PLACED");
        setOddsPositionArr(bet.data.dataobj.position);
        if (bet.data.dataobj.user_bal)
          localStorage.setItem("player_bal", bet.data.dataobj.user_bal);
        if (bet.data.dataobj.user_exp)
          localStorage.setItem("player_exp", bet.data.dataobj.user_exp);
        window.dispatchEvent(new Event("storage"));
        getMatchBetsOfMatchOfUser();
        Swal.fire({
          position: "top-end",
          icon: "success",
          background: "#2b2d3a",
          color: "#fff",
          title: "Bet Placed Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          background: "#2b2d3a",
          color: "#fff",
          title: bet.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      closeBetDrawer();
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        background: "#2b2d3a",
        color: "#fff",
        title: err?.response?.data?.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      console.log("ERROR LOG", err?.response?.data?.msg);
      console.log(err);
    }
  };
  const handleBookMakerBetPlaced = async (amount, betInfo_obj) => {
    try {
      try {
        var betPlacedInfo = await axios.post("/transactions/place-bet", {
          bet_amount: amount,
          bet_on: betInfo_obj.team,
          bet_rate: betInfo_obj.rate,
          opponent_team: betInfo_obj.team == Home ? Away : Home,
          bet_type: betInfo_obj.type,
          position: positionArr,
          market_id: market_id,
          match_id: match_id,
          user_id: localStorage.getItem("user_id"),
          team1: Home,
          team2: Away,
        });
      } catch (err) {
        Swal.fire({
          position: "top-end",
          icon: "error",
          background: "#2b2d3a",
          color: "#fff",
          title: err.response.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      if (betPlacedInfo?.data.status) {
        console.log("BET PLACED INFO", betPlacedInfo);
        setPositionArr(betPlacedInfo.data.dataobj.position);
        if (betPlacedInfo.data.dataobj.user_bal)
          localStorage.setItem(
            "player_bal",
            betPlacedInfo.data.dataobj.user_bal
          );
        if (betPlacedInfo.data.dataobj.user_exp)
          localStorage.setItem(
            "player_exp",
            betPlacedInfo.data.dataobj.user_exp
          );
        window.dispatchEvent(new Event("storage"));
        getMatchBetsOfMatchOfUser();
        Swal.fire({
          position: "top-end",
          icon: "success",
          background: "#2b2d3a",
          color: "#fff",
          title: "Bet Placed Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: betPlacedInfo.data.msg,
          background: "#2b2d3a",
          color: "#fff",
          showConfirmButton: false,
          timer: 1500,
        });
        closeBetDrawer();
      }
    } catch (err) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        background: "#2b2d3a",
        color: "#fff",
        title: err.response.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      closeBetDrawer();
      console.log("ERROR LOG", err.response.data.msg);
      console.log(err);
    }

    closeBetDrawer();
  };

  // const getSessionBets = async () => {
  //   try {
  //     const sessionBets = await axios.get("/transactions/getAllSessionBets", {
  //       params: {
  //         match_id,
  //         user_id: localStorage.getItem("user_id"),
  //       },
  //     });
  //     console.log(sessionBets.data);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const handleFancybetPlaced = async (amount, betInfo_obj) => {
    try {
      try {
        var betPlacedInfo = await axios.post("/transactions/place-fancy-bet", {
          bet_amount: amount,
          runnerName: betInfo_obj.runnerName,
          bet_rate:
            betInfo_obj.type == "No"
              ? betInfo_obj.laySize1
              : betInfo_obj.backSize1,
          bet_type: betInfo_obj.type,
          position: fancyPositionArr,
          market_id: market_id,
          match_id: match_id,
          selection_id: betInfo_obj.selection_id,
          user_id: localStorage.getItem("user_id"),
          team1: Home,
          team2: Away,
          fancy_details: betInfo_obj,
        });

        // getSessionBets();
        if (betPlacedInfo?.data.status) {
          console.log("BET PLACED INFO", betPlacedInfo);
          if (betPlacedInfo.data.dataobj.user_bal)
            localStorage.setItem(
              "player_bal",
              betPlacedInfo.data.dataobj.user_bal
            );
          if (betPlacedInfo.data.dataobj.user_exp)
            localStorage.setItem(
              "player_exp",
              betPlacedInfo.data.dataobj.user_exp
            );
          window.dispatchEvent(new Event("storage"));
          Swal.fire({
            position: "top-end",
            icon: "success",
            background: "#2b2d3a",
            color: "#fff",
            title: "Bet Placed Successfully",
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            background: "#2b2d3a",
            color: "#fff",
            title: betPlacedInfo.data.msg,
            showConfirmButton: false,
            timer: 1500,
          });
          closeBetDrawer();
        }
        getFancyBet();
      } catch (error) {
        closeBetDrawer();
        Swal.fire({
          position: "top-end",
          icon: "error",
          background: "#2b2d3a",
          color: "#fff",
          title: error.response.data.msg,
          showConfirmButton: false,
          timer: 1500,
        });
        console.log(error);
      }
    } catch (error) {
      closeBetDrawer();
      Swal.fire({
        position: "top-end",
        icon: "error",
        background: "#2b2d3a",
        color: "#fff",
        title: error.response.data.msg,
        showConfirmButton: false,
        timer: 1500,
      });
      console.log(error);
    }
    closeBetDrawer();
  };
  const totalAmountUndeclaredBet = (uBets) => {
    return uBets.reduce((count, i) => {
      if (i.fancy_result == "undeclared") {
        return count + Number(i.bet_amount);
      }
      return count;
    }, 0);
  };
  const getFancyBet = async () => {
    try {
      const response = await axios.get("/transactions/getAllSessionBets", {
        params: {
          match_id,
          user_id: localStorage.getItem("user_id"),
        },
      });
      if (response.data.status) {
        setFancySessionBet(response.data.dataobj);
        setTotalUndeclaredBet(totalAmountUndeclaredBet(response.data.dataobj));
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMatchBetsOfMatchOfUser = async () => {
    try {
      const resp = await axios.get("/users/getMatchBetsOfMatchOfUser", {
        params: {
          match_id,
          user_id: localStorage.getItem("user_id"),
        },
      });
      if (resp.data?.status) {
        setPlayedMatchBets(resp.data.dataobj);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    let useEffectMarket_id = "";
    // getSessionBets();

    const getMarketList = async () => {
      try {
        let res = await axios.get("/t-p/getMarketList", {
          params: {
            match_id,
          },
        });
        console.log("Market List", res.data.dataobj[0].marketId);
        if (res.data.status) {
          setmarket_id(res.data.dataobj[0].marketId);
          useEffectMarket_id = res.data.dataobj[0].marketId;
          setSessionBets(res.data.dataobj);
          getBookMakerMarket();
          getDimandFancy();
          getRunners();
          getOdds();
        }
      } catch (err) {
        console.log(err);
      }
    };
    getMarketList();
    const getPositionForUser = async () => {
      try {
        console.log("THIS API WAS CALLED");
        let res = await axios.get(
          "/transactions/getRecentPositionForTheMatch",
          {
            params: {
              match_id: match_id,
              user_id: localStorage.getItem("user_id"),
            },
          }
        );
        console.log("THIS API RETURED THIS", res.data);
        if (res.data?.status) {
          if (res.data.data.bookmaker) setPositionArr(res.data.data.bookmaker);
          if (res.data.data.odds) setOddsPositionArr(res.data.data.odds);
          if (res.data.data.fancy) setFancyPositionArr(res.data.data.fancy);
        }
      } catch (err) {
        console.log("THIS API RETURED ERROR", err);
        console.log(err);
      }
    };

    const getRunners = async () => {
      console.log("market Id", market_id);
      try {
        const response = await axios.get("/t-p/getRunners", {
          params: {
            market_id: useEffectMarket_id,
          },
        });

        setMatchStartTime(response.data.dataobj[0].marketStartTime);
        let default_position = {};
        // default_position[response.data.dataobj[0].runners[0]?.runnerName] = 0;
        // default_position[response.data.dataobj[0].runners[1]?.runnerName] = 0;
        const runners = response.data.dataobj[0].runners;
        runners[0]?.runner
          ? setHome(runners[0]?.runner)
          : setHome(runners[0]?.runnerName);
        // console.log("home - ",response.data.dataobj[0].runners[0].runnerName);
        runners[1]?.runner
          ? setAway(runners[1]?.runner)
          : setAway(runners[1]?.runnerName);
        for (let i = 0; i < runners.length; i++) {
          if (
            runners[i]?.runnerName == "The Draw" ||
            runners[i]?.runner == "The Draw"
          )
            continue;
          default_position[runners[i]?.runnerName || runners[i]?.runner] = 0;
        }
        console.log("Position", { runners, default_position });
        setPositionArr(default_position);
        setOddsPositionArr(default_position);
        getPositionForUser();
        // console.log("away - ",response.data.dataobj[0].runners[1].runnerName);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const getBookMakerMarket = async () => {
      try {
        const response = await axios.get("/t-p/bookMakerMarket", {
          params: {
            match_id,
          },
        });
        if (response.data.status) {
          setbookMaker(response.data.dataobj);
          // console.log("res bookmaker", response.data.dataobj);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const getOdds = async () => {
      console.log("market idddd", market_id);
      try {
        const response = await axios.get("/t-p/getOdds", {
          params: {
            market_id: useEffectMarket_id,
          },
        });

        // console.log("get Oddssss",response);
        if (
          response.data.status &&
          Array.isArray(response.data.dataobj) &&
          response.data.dataobj.length > 0
        ) {
          setodds(response.data.dataobj);

          // console.log("res bookmaker", response.data.dataobj);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDimandFancy = async () => {
      try {
        const response = await axios.get("/t-p/diamondFancy", {
          params: {
            match_id,
          },
        });
        console.log("df", response.data.status);
        if (response.data.status) {
          setdiamondFancy(response.data.dataobj);
          console.log("diamandfancy", diamondFancy);
        }
      } catch (error) {
        console.log(error);
      }
    };

    // const getScore = async () => {
    //   try {
    //     const response = await axios.get("/t-p/getScore", {
    //       params: {
    //         match_id,
    //       },
    //     });

    //     if (response.data.status) {
    //       console.log("score", response.data.dataobj.data);
    //       setScore(response.data.dataobj.data);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }

    //   // console.log("scorecard",score);
    // };

    // const getLiveLine = async () => {
    //   try {
    //     const response = await axios.get("/t-p/LiveLine", {
    //       params: {
    //         sport_id: 4,
    //         match_id,
    //       },
    //     });

    //     if (response.data.status) {
    //       console.log("LiveLine", response.data.dataobj);
    //       setLiveline(response.data.dataobj);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // getLiveLine();

    // const getLiveTV = async () => {
    //   try {
    //     const response = await axios.get("/t-p/live-tv", {
    //       params: {
    //         match_id,
    //       },
    //     });

    //     if (response.data.status) {
    //       console.log("Live TV", response.data.dataobj);
    //       setLiveTV(response.data.dataobj);
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   }
    // };

    // getLiveTV();

    const getFancyResult = async () => {
      try {
        const response = await axios.get("/t-p/getFancyResult", {
          params: {
            match_id,
          },
        });
        console.log("df", response.data.status);
        if (response.data.status) {
          setdiamondFancy(response.data.dataobj);
          console.log("diamandfancy", diamondFancy);
        }
      } catch (error) {
        console.log(error);
      }
    };

    getFancyBet();

    // const intervalIdGR = setInterval(getRunners, 4000);
    let intervalIdBM = setInterval(getBookMakerMarket, 1000);
    let intervalIOdds = setInterval(getOdds, 1000);
    let intervalDF = setInterval(getDimandFancy, 1000);
    const declareFancyResult = setInterval(getFancyResult, 5000);
    // let intervalScore = setInterval(getScore, 1000);
    // let intervalLiveLine = setInterval(getLiveLine, 1000);

    return () => {
      console.log("THIS WAS TRIGGERD < PAGE EXITED", {
        intervalIdBM,
        intervalIOdds,
        intervalDF,
        declareFancyResult,
        // intervalIdGR,
      });
      clearInterval(intervalIdBM);
      clearInterval(intervalIOdds);
      clearInterval(intervalDF);
      clearInterval(declareFancyResult);
      // clearInterval(intervalIdGR);
    };
  }, []);

  //Render Functions
  const oddsTable = () => {
    return (
      <div>
        <div
          className="betting"
          style={{ padding: "10px 0", margin: "16px 0" }}
        >
          <h5>Match Odds </h5>
          <h6>Market : Min : 100.0 | Max : 10000.0</h6>
          <table>
            <thead>
              <tr>
                <th>Runner</th>
                {/* <th colSpan={2}></th> */}
                <th>Back</th>
                <th>Lay</th>
                <th>Position</th>
              </tr>
            </thead>
            <tbody>
              {(
                odds[0].runners.sort(
                  (a, b) => a.sortPriority - b.sortPriority
                ) || []
              ).map((item, index) => {
                if (item.runner === "The Draw") return;
                return (
                  <tr>
                    <td>{item.runner}</td>
                    <td
                      style={{ width: "60px", backgroundColor: "#61acde" }}
                      onClick={() => {
                        handleBettingClicked(
                          "lagai",
                          item?.runner,
                          item?.ex?.availableToBack[0]?.price,
                          index,
                          "odds",
                          handleOddsBetPlaced
                        );
                      }}
                    >
                      {item?.ex?.availableToBack[0]?.price}
                    </td>
                    <td
                      style={{ width: "60px", backgroundColor: "#f6b771" }}
                      onClick={() => {
                        handleBettingClicked(
                          "khai",
                          item?.runner,
                          item?.ex?.availableToLay[0]?.price,
                          index,
                          "odds",
                          handleOddsBetPlaced
                        );
                      }}
                    >
                      {item?.ex?.availableToLay[0]?.price}
                    </td>
                    <td>{parseInt(oddsPositionArr[item.runner]) || 0}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const bookMakerTable = () => {
    const tiedMatch = bookMaker.find((item) => item.marketName == "Tied Match");
    const bookMakerMatch = bookMaker.find(
      (item) => item.marketName == "Bookmaker"
    );
    return (
      <>
        {bookMakerMatch && (
          <div
            className="betting"
            style={{ padding: "10px 0", margin: "16px 0" }}
          >
            <h5>Bookmaker </h5>
            <h6>Market : Min : 100.0 | Max : 50000.0</h6>
            <table>
              <thead>
                <tr>
                  <th>Runner</th>
                  <th>Back</th>
                  <th>Lay</th>
                  <th>Position</th>
                </tr>
              </thead>
              <tbody>
                {(
                  bookMakerMatch.runners.sort(
                    (a, b) => a.sortPriority - b.sortPriority
                  ) || []
                ).map((item, index) => {
                  if (item.runnerName === "The Draw") return <></>;
                  return (
                    <tr>
                      <td>{item.runnerName}</td>
                      {item.status == "ACTIVE" ? (
                        <>
                          <td
                            style={{
                              width: "60px",
                              backgroundColor: "#61acde",
                            }}
                            onClick={() => {
                              handleBettingClicked(
                                "lagai",
                                item.runnerName,
                                item?.ex?.availableToBack[0]?.price,
                                index,
                                "bookmaker",
                                handleBookMakerBetPlaced
                              );
                            }}
                          >
                            {item?.ex?.availableToBack[0]?.price}
                          </td>
                          <td
                            style={{ width: "60px", background: "#f6b771" }}
                            onClick={() => {
                              handleBettingClicked(
                                "khai",
                                item.runnerName,
                                item?.ex?.availableToLay[0]?.price,
                                index,
                                "bookmaker",
                                handleBookMakerBetPlaced
                              );
                            }}
                          >
                            {item?.ex?.availableToLay[0]?.price}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            colSpan={2}
                            style={{ color: "red", borderColor: "white" }}
                          >
                            {item.status}
                          </td>
                        </>
                      )}
                      <td>{parseInt(positionArr[item.runnerName]) || 0}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {tiedMatch && false && (
          <div
            className="betting"
            style={{ padding: "10px 0", margin: "16px 0" }}
          >
            <h5>{tiedMatch.marketName} </h5>
            <h6>Market : Min : 100.0 | Max : 50000.0</h6>
            <table>
              <thead>
                <tr>
                  <th>Runner</th>
                  <th>Back</th>
                  <th>Lay</th>
                  <th>Position</th>
                </tr>
              </thead>
              <tbody>
                {(
                  tiedMatch.runners.sort(
                    (a, b) => a.sortPriority - b.sortPriority
                  ) || []
                ).map((item, index) => {
                  if (item.runnerName === "The Draw") return <></>;
                  return (
                    <tr>
                      <td>{item.runnerName}</td>
                      {item.status == "ACTIVE" ? (
                        <>
                          <td
                            style={{
                              width: "60px",
                              backgroundColor: "#61acde",
                            }}
                            onClick={() => {
                              handleBettingClicked(
                                "lagai",
                                item.runnerName,
                                item?.ex?.availableToBack[0]?.price,
                                index,
                                "bookmaker",
                                handleBookMakerBetPlaced
                              );
                            }}
                          >
                            {item?.ex?.availableToBack[0]?.price}
                          </td>
                          <td
                            style={{
                              width: "60px",
                              backgroundColor: "#f6b771",
                            }}
                            onClick={() => {
                              handleBettingClicked(
                                "khai",
                                item.runnerName,
                                item?.ex?.availableToLay[0]?.price,
                                index,
                                "bookmaker",
                                handleBookMakerBetPlaced
                              );
                            }}
                          >
                            {item?.ex?.availableToLay[0]?.price}
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            colSpan={2}
                            style={{ color: "red", borderColor: "white" }}
                          >
                            {item.status}
                          </td>
                        </>
                      )}
                      <td>{parseInt(positionArr[item.runnerName])}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  };

  const tossTable = () => {
    return (
      <div
        className="toss_betting"
        style={{ padding: "10px 0", margin: "16px 0" }}
      >
        <h5>Toss </h5>
        <h6>Market : Min : 100.0 | Max : 50000.0</h6>

        <table>
          <thead>
            <tr>
              <th>Session : Toss Win</th>
              <th>Yes</th>
              <th>No</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Home}</td>
              <td>1</td>
              <td>1</td>
            </tr>
            <tr>
              <td>{Away}</td>
              <td>1</td>
              <td>1</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const diamandFancyTable = () => {
    return (
      <>
        <div
          className="toss_betting"
          style={{ padding: "10px 0", margin: "16px 0" }}
        >
          <h5>Fancy </h5>
          <h6>
            Market : Min : 100.0 | Max : 50000.0 || TotalBet :{" "}
            {-1 * totalUndeclaredBet}
          </h6>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>No</th>
                <th>Rate</th>
                <th>Yes</th>
                <th>Rate</th>
              </tr>
            </thead>
            <tbody>
              {diamondFancy.map((item) => {
                return (
                  <>
                    {item.GameStatus == "" ? (
                      <tr>
                        <td>{item.RunnerName}</td>
                        <td
                          style={{ width: "60px", backgroundColor: "#61acde" }}
                          onClick={() => {
                            handleFancyBetClick(
                              item.SelectionId,
                              item.RunnerName,
                              item.LayPrice1,
                              item.LaySize1,
                              item.BackPrice1,
                              item.BackSize1,
                              "No",
                              handleFancybetPlaced
                            );
                          }}
                        >
                          {item.LayPrice1}
                        </td>
                        <td>{item.LaySize1 / 100}</td>
                        <td
                          style={{ width: "60px", backgroundColor: "#f6b771" }}
                          onClick={() => {
                            handleFancyBetClick(
                              item.SelectionId,
                              item.RunnerName,
                              item.LayPrice1,
                              item.LaySize1,
                              item.BackPrice1,
                              item.BackSize1,
                              "Yes",
                              handleFancybetPlaced
                            );
                          }}
                        >
                          {item.BackPrice1}
                        </td>
                        <td>{item.BackSize1 / 100}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>{item.RunnerName}</td>
                        <td
                          colSpan={4}
                          style={{ color: "red", borderColor: "white" }}
                        >
                          {item.GameStatus}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  function calculateOversAndBalls(totalBalls) {
    // Calculate overs
    var overs = Math.floor(totalBalls / 6);
    // Calculate remaining balls
    var balls = totalBalls % 6;

    return overs + "." + balls;
  }
  const detailedBetting = () => {
    return (
      <div
        className="session_betting"
        style={{ padding: "10px 0", margin: "16px 0" }}
      >
        <h6>Session : Min : 100.0 | Max : 100000.0</h6>
        <table>
          <thead>
            <tr>
              <th>Session</th>
              <th>No</th>
              <th>Rate</th>
              <th>Yes</th>
              <th>Rate</th>
            </tr>
          </thead>
          <tbody>
            {sessionBets?.map((item) => (
              <tr>
                <td className="bet_cond">{item.marketName}</td>
                <td>10</td>
                <td>0.0</td>
                <td>37</td>
                <td>0.0</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const playedMatchbetsTable = () => {
    return (
      <div
        className="session_betting"
        style={{ padding: "10px 0", margin: "16px 0" }}
      >
        <h5>Placed Match Bets</h5>
        <table>
          <thead>
            <tr style={{ overflow: "scroll" }}>
              {/* <th style={{ width: 150 }}>Match</th> */}
              <th>Type</th>
              <th>Runner Name</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Mode</th>
            </tr>
          </thead>
          <tbody>
            {playedMatchBets?.map((item) => (
              <tr>
                <td>{item.type}</td>
                <td>{item.bet_on}</td>
                <td>{parseFloat(item.bet_rate)}</td>
                <td>{item.bet_amount}</td>
                <td>{item.bet_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const playedFancyBets = () => {
    return (
      <div
        className="session_betting"
        style={{ padding: "10px 0", margin: "16px 0" }}
      >
        <h5>Placed Fancy</h5>
        <h6>Total bet amount: {-1 * totalUndeclaredBet}</h6>
        <table>
          <thead>
            <tr style={{ overflow: "scroll" }}>
              <th style={{ width: 150 }}>Session</th>
              <th>Runs</th>
              <th>Rate</th>
              <th>Amount</th>
              <th>Mode</th>
            </tr>
          </thead>
          <tbody>
            {fancySessionBet?.map((item) => (
              <tr>
                <td>{item.fancy_Detail.runnerName}</td>

                <td>
                  {item.bet_type == "Yes"
                    ? item.fancy_Detail.backPrice1
                    : item.fancy_Detail.layPrice1}
                </td>
                <td>{parseInt(item.bet_rate) / 100}</td>
                <td>{item.bet_amount}</td>
                <td>{item.bet_type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const closeBetDrawer = () => {
    setIsBettingDrawerOpen(false);
    setIsFancyBettingDrawerOpen(false);
  };
  return (
    <div>
      {/* <div
        className="headerInfo"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "16px",
          height: "15vh",
        }}
      >
        <div
          style={{
            flex: 3,
            background:
              "linear-gradient(135deg, rgba(141,115,255,1) 47%, rgba(246,2,220,1) 80%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div>
            <span>
              {score?.[2]?.t1} - {score?.[2]?.score}/{score?.[2]?.wicket}(
              {calculateOversAndBalls(score?.[2]?.ballsdone)})
            </span>
            <br />
            <span>
              {score?.[2]?.t2} - {score?.[2]?.score2}/{score?.[2]?.wicket2}(
              {calculateOversAndBalls(score?.[2]?.ballsdone2)})
            </span>
          </div>
        </div>
        <div
          style={{
            flex: 2,
            width: "100%",
            background:
              "linear-gradient(135deg, rgba(246,2,220,1) 47%, rgba(141,115,255,1) 90%)",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          S Toss Status
        </div>
      </div> */}
      <div
        style={{
          padding: "16px 0",
          display: "grid",
          placeItems: "center",
          marginBottom: "8px",
        }}
      >
        <Tabs
          value={activeTab}
          sx={{
            ".Mui-selected": {
              color: `#fff`,
              outline: "none",
            },
            "&:hover": {
              outline: "none",
            },
          }}
          TabIndicatorProps={{ style: { background: "#ff96a5" } }}
          aria-label="tabs example"
          onChange={(e, newVal) => {
            setActiveTab(newVal);
          }}
        >
          <Tab
            value={0}
            label={
              <span style={{ color: activeTab == 0 ? "#ff96a5" : "#fff" }}>
                Scoreboard
              </span>
            }
          />
          <Tab
            value={1}
            label={
              <span style={{ color: activeTab == 1 ? "#ff96a5" : "#fff" }}>
                Live Telecast
              </span>
            }
          />
        </Tabs>
      </div>
      {activeTab === 0 && (
        <div style={{ width: "100%", objectFit: "contain" }}>
          {/* <iframe srcDoc={liveLine} style={{ width: "100%", height: "40vh" }} /> */}
          <IframeRenderer
            url={`https://diamondapi.uk/dcasino/sr.php?eventid=${match_id}&sportid=4`}
            key={"test"}
          />
        </div>
      )}
      {activeTab === 1 && (
        <div style={{ width: "100%", objectFit: "contain" }}>
          {/* <iframe srcDoc={liveLine} style={{ width: "100%", height: "40vh" }} /> */}
          <IframeRenderer
            url={`https://diamondapi.uk/dcasino/nntv.php?MatchID=${match_id}`}
            key={"test2"}
          />
        </div>
      )}
      <div className="tables">
        {new Date(matchStartTime) > new Date() && tossTable()}
        {odds && odds[0]?.status == "OPEN" && oddsTable()}
        {bookMaker && bookMaker[0]?.status == "OPEN" && bookMakerTable()}
        {diamondFancy.length && diamandFancyTable()}
        {/* {detailedBetting()} */}
        {playedMatchBets.length > 0 && playedMatchbetsTable()}
        {fancySessionBet.length > 0 && playedFancyBets()}
      </div>
      <div>
        {upcomingMatches.map((item) => (
          <GameListItem
            dateTime={"09 Dec, 10:00AM"}
            teams={"Ind vr Pak"}
            mBets={0}
            sBets={0}
            onClick={() => navigate("/match/" + item)}
          />
        ))}
      </div>
      <SwipeableDrawer
        open={isBettingDrawerOpen}
        anchor="bottom"
        onClose={() => setIsBettingDrawerOpen(false)}
      >
        <BetDrawer
          type={betInfo.type}
          team={betInfo.team}
          rate={betInfo.rate}
          position={positionArr[betInfo.positionIndex]}
          closeBetDrawer={closeBetDrawer}
          currState={betInfo}
          onBet={betInfo.placeBetFun}
        />
      </SwipeableDrawer>

      <SwipeableDrawer
        open={isFancyBettingDrawerOpen}
        anchor="bottom"
        onClose={() => setIsFancyBettingDrawerOpen(false)}
      >
        <BetDrawer
          type={fancyBetInfo.type}
          team={fancyBetInfo.runnerName}
          rate={
            fancyBetInfo.type == "No"
              ? fancyBetInfo.laySize1
              : fancyBetInfo.backSize1
          }
          position={positionArr[betInfo.positionIndex]}
          closeBetDrawer={closeBetDrawer}
          currState={fancyBetInfo}
          onBet={fancyBetInfo.placeBetFun}
        />
      </SwipeableDrawer>
    </div>
  );
}
export default Match;
