import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const engRules = [
  "Please give a few minutes to understand rules of Flybet9 here, as best as you can.",
  "Change your password after you log in.",
  "All advance bets will be accepted after the toss.",
  "For every match, 0/- coins will be charged.",
  "0/- coins will be charged if the user will not play any Market bet or Session bet in a match.",
  "If the game is canceled or tied, then all deals will be canceled, and transactions will be done on session and fancy, which are completed.",
  "The deal of the match is at least 2000.0 and maximum 1000000.0, and the deal of session is at least 1000.0 and maximum 50000.0.",
  "Live draw is settled on TV score, rate is never changed. This is a good chance for users.",
  "During the match, please bet only after confirming the deal. Once the deal is confirmed, it cannot be changed or removed. Responsibility for every deal is yours.",
  "All transactions will be validated from the ledger only.",
  "Transactions will be canceled in case the result is stuck in the casino.",
  "In an inside-out game, 25% will be paid out when the first card is opened.",
  "Also read the casino rules before playing the game.",
  "Arguments of any kind will not be accepted in the casino.",
  "It'll be the user's responsibility for an internet connection problem.",
  "Note: If some kind of breakdown occurs in the server or website, only successful bets will be accepted. In such a situation, any kind of debate will be invalid.",
];
const hindiRules = [
  "कृपया Flybet9 के नियमों को समझने के लिए यहां कुछ मिनट दें, और अपने अनुसार समझ लें |",
  "लॉग इन करने के बाद अपना पासवर्ड बदल लें |",
  " प्रत्येक गेम के लिए 0 /- कॉइन्स चार्ज रहेगा |",
  "यदि आप मैच या सेशन का एक भी सौदा नहीं करते हो, ऐसे में आपसे 0/- कॉइन्स का चार्ज लिया जायेगा |",
  "सभी एडवांस सौदे टॉस के बाद लिए जाएंगे |",
  "खेल रद्द या टाई होने पर सभी सौदे रद्द कर दिए जाएंगे और लेनदेन सेशन और फैंसी जो पूरा हो गया है उस पर किया जाएगा |",
  "मैच का सौदा कम से कम 2000.0 और अधिकतम 1000000.0 है और सेशन का सौदा कम से कम 1000.0 और अधिकतम 50000.0 है।",
  "लाइव ड्रा टीवी स्कोर पर निर्भर है | दर कभी नहीं बदली जाती है | यह यूजर के लिए अच्छा मौका है |",
  "मैच के दौरान भाव को देख और समझ कर ही सौदा करें | किये गए किसी भी सौदे को हटाया या बदला नहीं जायेगा | सभी सौदे के लिए आप स्वयं जिम्मेवार हैं |",
  "यहाँ सभी सौदे लेजर से मान्य किये जायेंगे |",
  "कैसीनो में परिणाम फंसने की स्थिति में लेनदेन रद्द कर दिया जाएगा।",
  "इनसाइड-आउट गेम में पहला कार्ड खोले जाने पर 25% का भुगतान किया जाएगा।",
  "गेम खेलने से पहले कैसीनो के नियम भी पढ़ें।",
  "कैसीनो में किसी भी प्रकार के तर्क स्वीकार नहीं किए जाएंगे।",
  "इंटरनेट कनेक्शन प्रॉब्लम की जिम्मेवारी आपकी रहेगी |",
  "नोट: सर्वर या वेबसाइट में किसी तरह की खराबी आने या बंद हो जाने पर केवल किए गए सौदे ही मान्य होंगे | ऐसी स्तिथि में किसी तरह का वाद-विवाद मान्य नहीं होगा |",
];

const marathiRule = [
  "कृपया Flybet9 चे नियम समजून घेण्यासाठी काही मिनिटे द्या, तुम्हाला शक्य तितके उत्तम.",
  "तुम्ही लॉग इन केल्यानंतर तुमचा पासवर्ड बदला.",
  "प्रत्येक सामन्यासाठी 0 /- नाणी आकारली जातील.",
  "वापरकर्त्याने सामन्यात मार्केट बेट किंवा सेशन बेट न खेळल्यास 0/- नाणी आकारली जातील.",
  "नाणेफेकीनंतर सर्व आगाऊ बेट स्वीकारले जातील.",
  "जर गेम रद्द झाला किंवा टाय झाला तर सर्व सौदे रद्द केले जातील आणि व्यवहार पूर्ण झालेल्या सत्र आणि फॅन्सीवर केले जातील.",
  "सामन्याची डील किमान 2000.0 आणि कमाल 1000000.0 आहे आणि सत्राची डील किमान 1000.0 आणि कमाल 50000.0 आहे.",
  "थेट ड्रॉ टीव्ही स्कोअरवर अवलंबून असतो. दर कधीही बदलत नाही. वापरकर्त्यासाठी ही एक चांगली संधी आहे.",
  "सामन्यादरम्यान किंमत पाहून आणि समजून घेतल्यानंतरच डील करा. केलेला कोणताही करार काढला जाणार नाही किंवा बदलला जाणार नाही. सर्व व्यवहारांसाठी तुम्ही पूर्णपणे जबाबदार आहात.",
  "येथे सर्व सौदे लेजरद्वारे प्रमाणित केले जातील.",
  "निकाल कॅसिनोमध्ये अडकल्यास, व्यवहार रद्द केला जाईल.",
  "इनसाइड-आउट गेममध्ये पहिले कार्ड उघड झाल्यावर 25% पैसे दिले जातील.",
  "गेम खेळण्यापूर्वी कॅसिनोचे नियम देखील वाचा.",
  "कॅसिनोमध्ये कोणत्याही प्रकारचे युक्तिवाद स्वीकारले जाणार नाहीत.",
  "इंटरनेट कनेक्शन समस्यांसाठी तुम्ही जबाबदार असाल.",
  "टीप: सर्व्हर किंवा वेबसाइटमध्ये कोणतीही चूक किंवा बंद झाल्यास, केवळ केलेले सौदे वैध असतील. अशा परिस्थितीत कोणत्याही प्रकारचा वाद ग्राह्य ठरणार नाही.",
];
const Rules = () => {
  const [ruleArr, setruleArr] = useState(engRules);
  const navigator = useNavigate();
  const handleAcceptRule = () => {
    navigator("/");
  };
  return (
    <div>
      <div style={{display:'flex',justifyContent:"center"}}>
        <button onClick={()=>{setruleArr(engRules)}}>English</button>
        <button onClick={()=>{setruleArr(hindiRules)}}>Hindi</button>
        <button onClick={()=>{setruleArr(marathiRule)}}>Marathi</button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "st",
          // justifyContent: "start",
          // height: "100vh",
          margin: "20px 40px",
        }}
      >
        {ruleArr.map((rule, index) => (
          <div key={index} style={{ marginBottom: "10px" }}>
            <p style={{ margin: "0px" }}>
              <strong>{` ${index + 1}: `}</strong>
              {rule}
            </p>
          </div>
        ))}
      </div>
      <div style={{ textAlign: "center" }}>
        <button
          onClick={handleAcceptRule}
          type="button"
          class="btn btn-success"
        >
          Accept Rules
        </button>
      </div>
    </div>
  );
};

export default Rules;
