import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logo from "../Assets/Logo.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton, SwipeableDrawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "../Componant/Sidebar/Sidebar";
import "./HomeLayout.scss";
import axios from "../authAxios";

function HomeLayout({ component }) {
  const navigate = useNavigate();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [userBal, setUserBal] = useState(localStorage.getItem("player_bal"));
  const [userExp, setUserExp] = useState(localStorage.getItem("player_exp"));
  const location = useLocation();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    localStorage.getItem("isUserLoggedIn")
  );
  const pathname = window.location.pathname;

  const handleLoginClick = () => {
    navigate("/login");
  };

  const handleOpenClose = () => {
    console.log("inside the homelaybout");
    setIsOptionsOpen(false);
  };

  useEffect(() => {
    setIsUserLoggedIn(localStorage.getItem("isUserLoggedIn"));
  }, [localStorage.getItem("isUserLoggedIn")]);

  useEffect(() => {
    const handleStorage = () => {
      setUserBal(localStorage.getItem("player_bal"));
      setUserExp(localStorage.getItem("player_exp"));
      setIsUserLoggedIn(localStorage.getItem("isUserLoggedIn"));
    };

    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var response = await axios.get("/users/userAccDetails", {
          params: { _id: localStorage.getItem("user_id") },
        });
        console.log(response);
        localStorage.setItem("player_exp", response.data.exposure);
        localStorage.setItem("player_bal", response.data.balance);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [pathname]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          position: "sticky",
          top: 0,
          backgroundColor: "#20222c",
          zIndex: "1",
        }}
      >
        <div style={{ transition: "width 2s" }}>
          {pathname !== "/" && (
            <IconButton
              onClick={() => navigate(-1)}
              style={{ outline: "none" }}
            >
              <ArrowBackIosIcon sx={{ color: "white" }} />
            </IconButton>
          )}
          <img height={"40px"} src={logo} alt="" />
        </div>
        <div>
          {isUserLoggedIn ? (
            <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "20px" }}
              >
                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                  Bal : {userBal}
                </span>
                <span style={{ fontSize: "18px", fontWeight: "700" }}>
                  Exp : {userExp}
                </span>
              </div>

              <IconButton
                style={{ outline: "none" }}
                onClick={() => setIsOptionsOpen(true)}
              >
                <MenuIcon sx={{ color: "white" }} />
              </IconButton>
            </div>
          ) : (
            <button
              style={{
                color: "white",
                backgroundColor: "#735cff",
                border: "none",
                padding: "5px 10px",
                borderRadius: "7px",
              }}
              onClick={handleLoginClick}
            >
              Login
            </button>
          )}
        </div>
      </div>
      <div style={{ padding: "10px 20px", overflowX: "auto" }}>{component}</div>
      <SwipeableDrawer
        open={isOptionsOpen}
        onClose={() => setIsOptionsOpen(false)}
      >
        <Sidebar display={true} handleOpenClose={handleOpenClose} />
      </SwipeableDrawer>
    </div>
  );
}

export default HomeLayout;
