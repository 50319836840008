import { Chip } from "@mui/material";
import React, { useState } from "react";
import "./betDrawer.scss";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

function BetDrawer({ type, team, rate, onBet, closeBetDrawer, currState }) {
  const [betAmount, setBetAmount] = useState("");
  const numberClicked = (e) => {
    const number = e.target.innerText;
    setBetAmount((prev) => prev.toString() + number);
  };
  const handleCancelClick = () => {
    closeBetDrawer();
  };
  return (
    <div style={{}}>
      <div
        className="betInfo"
        style={{
          border:"none",
          // background: "rgba(141,115,255,1)",

          // "linear-gradient(131deg, rgba(141,115,255,1) 26%, rgba(246,2,220,1) 53%, rgba(141,115,255,1) 80%)",
          color: "white",
        }}
      >
        <span style={{background:"#ff96a5", borderRadius:"0px"}}>{team}</span>
        <span style={{background:"#ff96a5" , borderRadius:"0px"}}>{type}</span>
        <span style={{background:"#ff96a5" , borderRadius:"0px"}}>{rate}</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "8px 16px",
          border:"1px solid black",
          borderRadius:"10px",
          margin:'0px 16px 16px 16px'
        }}
      >
        <span>{betAmount || "Enter the amount"}</span>
        <span
          onClick={() => setBetAmount((prev) => prev.slice(0, -1))}
          style={{ color: "gray" }}
        >
          <BackspaceOutlinedIcon />
        </span>
      </div>
      {/* QUICK SELECT */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "4px 16px",
          width: "100%",
          //   overflowX: "",
        }}
      >
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("1000")} label="1H" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("5000")} label="5H" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("10000")} label="10H" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("25000")} label="25H" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("50000")} label="50H" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "4px 16px",
          width: "100%",
          //   overflowX: "",
        }}
      >
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("100000")} label="1L" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("200000")} label="2L" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("500000")} label="5L" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("1000000")} label="10L" />
        <Chip style={{backgroundColor:"#ff96a5",fontWeight:700}} onClick={() => setBetAmount("2500000")} label="25L" />
      </div>
      <div className="bottom">
        <table>
          <tbody>
            <tr>
              <td className="numberPadBtn" onClick={numberClicked}>1</td>
              <td className="numberPadBtn" onClick={numberClicked}>2</td>
              <td className="numberPadBtn" onClick={numberClicked}>3</td>
            </tr>
            <tr>
              <td className="numberPadBtn" onClick={numberClicked}>4</td>
              <td className="numberPadBtn" onClick={numberClicked}>5</td>
              <td className="numberPadBtn" onClick={numberClicked}>6</td>
            </tr>
            <tr>
              <td className="numberPadBtn" onClick={numberClicked}>7</td>
              <td className="numberPadBtn" onClick={numberClicked}>8</td>
              <td className="numberPadBtn" onClick={numberClicked}>9</td>
            </tr>
            <tr>
              <td className="numberPadBtn"></td>
              <td className="numberPadBtn" onClick={numberClicked}>0</td>
              <td className="numberPadBtn" onClick={() => setBetAmount("")}>C</td>
            </tr>
          </tbody>
        </table>
         {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'center',  }}>
        <div className="numberPadBtn" onClick={numberClicked}>1</div>
        <div className="numberPadBtn" onClick={numberClicked}>2</div>
        <div className="numberPadBtn" onClick={numberClicked}>3</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center',  }}>
        <div className="numberPadBtn" onClick={numberClicked}>4</div>
        <div className="numberPadBtn" onClick={numberClicked}>5</div>
        <div className="numberPadBtn" onClick={numberClicked}>6</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center',  }}>
        <div className="numberPadBtn" onClick={numberClicked}>7</div>
        <div className="numberPadBtn" onClick={numberClicked}>8</div>
        <div className="numberPadBtn" onClick={numberClicked}>9</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center',  }}>
        <div className="numberPadBtn"></div>
        <div className="numberPadBtn" onClick={numberClicked}>0</div>
        <div className="numberPadBtn" onClick={() => setBetAmount("")}>C</div>
      </div> */}
    {/* </div> */}
        <div className="betInfo" style={{ marginTop: "10px",border:"none" }}>
          {/* <button style={{ background: "red" }}>Cancel</button> */}
          <button style={{backgroundColor:"red",border:'none', fontSize:"20px",fontWeight:"800", width:'fit-content',borderRadius:'10px', padding:"8px 30px"}} onClick={handleCancelClick}>
            Cancel
          </button>
          <button
          style={{border:'none', fontSize:"20px",fontWeight:"800", width:'fit-content',borderRadius:'10px', padding:"8px 30px"}}
            onClick={() => {
              console.log("Bet Clicked");
              onBet(betAmount, currState);
            }}
          >
            BET
          </button>
        </div>
      </div>
    </div>
  );
}

export default BetDrawer;
