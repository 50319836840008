import React from "react";
import { useNavigate } from "react-router-dom";
import crkImg from "../../Assets/cricketImg.jpg";
import ledgerImg from "../../Assets/Ledger.jpg";
import statementImg from "../../Assets/Statement2.jpg";
import cricketImg from "../../Assets/Cricket.jpg";
import socckerImg from "../../Assets/Football.jpg";
import TennisImg from "../../Assets/Tennis.jpg";
import tempImg from "../../Assets/temp1.jpg";
import "./Home.scss";
import InPlayOption from "../../Componant/InPlayOption";
import { Grid } from "@mui/material";
export default function Home() {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleMenuClick = (path) => {
    if (localStorage.getItem("isUserLoggedIn")) {
      navigate(path);
      return;
    }
    navigate("/login");
  };
  const onlickInplayOption = (game) => {
    navigate("/inplay");
  };

  // const MenuItem = ({ label, bgImg, onClick }) => {
  //   return (
  //     // <Grid item xs={12} sm={6} md={4} lg={3} xl={2} sx={{ padding: "8px" }}>
  //       <div
  //         style={{
  //           // whiteSpace: "nowrap",

  //           width: "100%",
  //           height: "80px",
  //           backgroundSize: "cover",
  //           backgroundPosition: "center",
  //           // backgroundImage: `url(${bgImg}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`,
  //           backgroundBlendMode: "overlay",
  //           border: "2px solid white",
  //           borderRadius: "5px",
  //           display: "flex",
  //           alignItems: "right",
  //           justifyContent: "",
  //         }}
  //         onClick={onClick}
  //       >
  //         <h4
  //           style={{
  //             alignSelf: "center",
  //             color: "white",
  //             // fontSize: "20px",
  //             fontWeight: "600",
  //             textAlign: "center",
  //           }}
  //         >
  //           {label}
  //         </h4>
  //       </div>
  //     // </Grid>
  //   );
  // };
  return (
    <div
      style={{
        // backgroundColor: "#20222c",
        width: "100%",
        height: "100%",
      }}
    >
      {/* <div style={{ padding: "10px 20px" }}> */}
      <div>
        <h3
          style={{
            color: "white",
            margin: "0px",
            fontFamily: " Poppins, sans-serif",
            fontWeight: "500",
            fontSize: "15px",
          }}
        >
          {localStorage.getItem("isUserLoggedIn")
            ? "Hello, " + localStorage.getItem("userName")
            : "Welcome to flybet9"}
        </h3>
        <h1
          style={{
            color: "#735cff",
            margin: "0px",
            textShadow: "0 0 4px blue",
            fontFamily: " Poppins, sans-serif",
            fontWeight: "700",
            fontSize: "50px",
          }}
        >
          {localStorage.getItem("isUserLoggedIn")
            ? "Enjoy Betting!"
            : "Join us Now!"}
        </h1>
        {localStorage.getItem("isUserLoggedIn") && (
          <h3
            style={{
              color: "white",
              marginTop: "15px",
              fontFamily: " Poppins, sans-serif",
              fontWeight: "500",
              fontSize: "15px",
            }}
          >
            Explore Trending Games & Earn
          </h3>
        )}
        {!localStorage.getItem("isUserLoggedIn") && (
          <h4
            style={{
              color: "white",
              margin: "0px",
              fontFamily: " Poppins, sans-serif",
              fontWeight: "500",
              fontSize: "12px",
            }}
          >
            Login now to enjoy best servies!
          </h4>
        )}
      </div>
      <div style={{ margin: "16px auto", display:'flex',overflow:"scroll"}}>
        <MenuItem
          path={"/inplay/4"}
          label={"In Play"}
          bgImg={tempImg}
        />
         <MenuItem
          path={"/livecasino"}
          label={"Casino"}
          bgImg={tempImg}
        />
        <MenuItem
          path={"/inplay/4"}
          label={"Cricket"}
          bgImg={ledgerImg}
        />
        <MenuItem
          path={"/inplay/1"}
          label={"Soccer"}
          bgImg={ledgerImg}
        />
          <MenuItem
          path={"/inplay/2"}
          label={"Tennis"}
          bgImg={tempImg}
        />
      </div>

      <div>
        <h5
          style={{
            color: "black",
            backgroundColor: "white",
            display: "inline-block",
            padding: "7px 25px",
            borderRadius: "5px",
          }}
        >
          Games
        </h5>
        <div
          className="inplayOptionClass"
          style={{
            display: "flex",
            overflowX: "scroll",
            justifyContent: "flex-start",
          }}
        >
          <InPlayOption
            matchCount={"12"}
            bgImg={crkImg}
            game={"Cricket"}
            onClick={onlickInplayOption}
          />
          <InPlayOption
            matchCount={"16"}
            bgImg={crkImg}
            game={"Soccer"}
            onClick={onlickInplayOption}
          />
          <InPlayOption
            matchCount={"24"}
            bgImg={crkImg}
            game={"Tennis"}
            onClick={onlickInplayOption}
          />
          <InPlayOption
            matchCount={"90"}
            bgImg={crkImg}
            game={"Live Casino"}
            onClick={onlickInplayOption}
          />
        </div>
      </div>
      <div>
        <h5
          style={{
            color: "black",
            backgroundColor: "white",
            display: "inline-block",
            padding: "7px 25px",
            borderRadius: "5px",
          }}
        >
          Live Casino
        </h5>
        <div
          className="inplayOptionClass"
          style={{
            display: "flex",
            overflowX: "scroll",
            justifyContent: "flex-start",
          }}
        >
          <InPlayOption matchCount={"12"} bgImg={crkImg} game={"Cricket"} />
          <InPlayOption matchCount={"16"} bgImg={crkImg} game={"Soccer"} />
          <InPlayOption matchCount={"24"} bgImg={crkImg} game={"Tennis"} />
          <InPlayOption matchCount={"90"} bgImg={crkImg} game={"Live Casino"} />
        </div>
      </div>
      <div>
        <h5
          style={{
            color: "black",
            backgroundColor: "white",
            display: "inline-block",
            padding: "7px 25px",
            borderRadius: "5px",
          }}
        >
          Latest updates
        </h5>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          <div
            style={{
              width: "450px",
              height: "400px",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundImage: `url(${crkImg})`,
              border: "2px solid white",
              borderRadius: "5px",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px 15px",
            }}
          ></div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

const MenuItem = ({ label, bgImg,path }) => {
  const navigate = useNavigate();

  const handleMenuClick = () => {
    navigate(path);
  };

  return (
    <div
      style={{
        minWidth :"130px",
        border:"2px solid white",
        borderRadius:"7px",
        padding:"10px",
        textAlign:'right',
        marginRight:"10px",
        background:'linear-gradient(#193FB0,#2157E9)'
      }}
      onClick={handleMenuClick}
    >
      <h5 style={{ alignSelf: "center", color: "white" , margin:"0px 5px"}}>{label}</h5>
    </div>
  );
};
