import logo from "./logo.svg";
import "./app.scss";
import Routes from "./Routes";
import Cookies from "js-cookie";
import axios from "./authAxios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
function App() {
  const [token, setToken] = useState(Cookies.get("jwtToken"));
  const navigate = useNavigate();
  useEffect(() => {
    setToken(Cookies.get("jwtToken"));
  }, [Cookies.get("jwtToken")]);
  console.log(token);
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;


  return <Routes />;
}

export default App;
