import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Rules from "./Pages/Rules/Rules";
import LiveCasino from "./Pages/LiveCasino/LiveCasino";
import Inplay from "./Pages/InPlay/Inplay";
import Ledger from "./Pages/Ledger/Ledger";
import Statement from "./Pages/Statement/Statement";
import HomeLayout from "./Layout/HomeLayout";
import Match from "./Pages/InPlay/Match/Match";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import SingleMatchLedger from "./Pages/Ledger/SingleMatch/SingleMatchLedger";
import NotFound from "./Pages/NotFound/NotFound";
import Cookies from "js-cookie";
import UpcommingMatches from "./Pages/UpcommingMatches/UpcommingMatches";

function AppRoutes() {
  return (
    <div>
      <PrivateRoutes />
    </div>
  );
}

export default AppRoutes;

const PrivateRoutes = () => {
  const navigate = useNavigate();
  console.log(Cookies.get("jwtToken"))
  console.log(localStorage.getItem("isUserLoggedIn"))
  if (Cookies.get("jwtToken") && localStorage.getItem("isUserLoggedIn")) {
    return (
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route
          path="/rules"
          element={<HomeLayout component={<Rules />} />}
        ></Route>
        <Route
          path="/upcoming"
          element={<HomeLayout component={<UpcommingMatches />} />}
        ></Route>
        <Route
          path="/livecasino"
          element={<HomeLayout component={<LiveCasino />} />}
        ></Route>
        <Route
          path="/inplay/:sports_id"
          element={<HomeLayout component={<Inplay />} />}
        ></Route>
        <Route
          path="/ledger"
          element={<HomeLayout component={<Ledger />} />}
        ></Route>
        <Route
          path="/statement"
          element={<HomeLayout component={<Statement />} />}
        ></Route>
        <Route
          path="/change-password"
          element={<HomeLayout component={<ChangePassword />} />}
        ></Route>
        <Route
          path="/match/:match_id"
          element={<HomeLayout component={<Match />} />}
        ></Route>
        <Route
          path="/ledger/:match_id"
          element={<HomeLayout component={<SingleMatchLedger />} />}
        ></Route>
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="*" element={<Login />} />;
      </Routes>
    );
  }
};
