import React, { useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { SlCalender } from "react-icons/sl";
import { MdOutlineClear } from "react-icons/md";
import Table from "../../Componant/Table/Table";
import axios from "../../authAxios";
export default function Statement() {
  const [value, onChange] = useState([new Date(), new Date()]);
  const [data, setData] = useState([]);
  const [apires, setApires] = useState([]);
  const columns = [
    // { name: "ID", selector: (row) => row.id },
    { name: "Date", selector: (row) => row.date },
    { name: "Description", width: 500, selector: (row) => row.description },
    { name: "Cr", selector: (row) => row.credit },
    { name: "Dbt", selector: (row) => row.debit },
    // { name: "Com+", selector: (row) => row.com_plus },
    // { name: "Com-", selector: (row) => row.com_minus },
    { name: "Balance", selector: (row) => row.balance },
  ];

  const getTableReadyData = (res) => {
    return res.map((item) => {
      return {
        date: item.date,
        description: item.description,
        cr: item.credit,
        dbt: item.debit,
        balance: item.balance,
      };
    });
  };

  useEffect(() => {
    const getStatements = async () => {
      try {
        const res = await axios.get("/users/getUserStatement", {
          params: {
            user_id: localStorage.getItem("user_id"),
          },
        });
        if (res.data.status) {
          setApires(res.data.dataobj);
          // setData(getTableReadyData(res.data.dataobj));
          setData(res.data.dataobj);
        }
      } catch (er) {
        console.log(er);
      }
    };
    getStatements();
  }, [value]);
  // const data = [
  //   {
  //     id: 1,
  //     date: "2023-01-01",
  //     description: "Initial",
  //     cr: 0,
  //     dbt: 0,
  //     com_plus: 0,
  //     com_minus: 0,
  //     balance: 3000,
  //   },
  //   {
  //     id: 2,
  //     date: "2023-01-02",
  //     description: "Deposit",
  //     cr: 4000,
  //     dbt: 0,
  //     com_plus: 0,
  //     com_minus: 0,
  //     balance: 7000,
  //   },
  //   {
  //     id: 3,
  //     date: "2023-01-03",
  //     description: "Withdrawal",
  //     cr: 0,
  //     dbt: 2000,
  //     com_plus: 0,
  //     com_minus: 0,
  //     balance: 1500,
  //   },
  // ];

  return (
    <div>
      {/* <DateRangePicker
        onChange={onChange}
        value={value}
        clearIcon={<MdOutlineClear color="white" />}
        calendarIcon={<SlCalender color="white" />}
        format={"dd-MM-y"}
      /> */}
      <Table columns={columns} data={data} title="Statement" />
    </div>
  );
}
