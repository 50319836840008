import React from "react";

export default function gameListItem(props) {
  return (
    <div
      style={{
        minWidth: "250px",
        width: "100%",
        borderRadius: "8px",
        margin: "16px 0",
        backgroundColor: "#c3c7f7",
      }}
      onClick={props.onClick}
    >
      <div
        style={{
          textAlign: "center",
          backgroundColor: "#735cff",
          padding: "16px",
          borderTopRightRadius: "8px",
          borderTopLeftRadius: "8px",
        }}
      >
        {props.dateTime}
      </div>
      <div style={{ padding: "8px 20px" }}>
        <h4>{props.teams}</h4>
        <h6>Matches Bets:{props.mBets}</h6>
        <h6>Session Bets:{props.sBets}</h6>
      </div>
    </div>
  );
}
